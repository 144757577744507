*,
*::after,
*::before {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.pay-section {
    .img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin-top: -2.5rem !important;
        }
    }
    .for-pay {
        display: flex;
        justify-content: flex-end;
        .payment-methods {
            background-color: #b7b7b7;
            height: 250px;
            div {
                width: 220px;
                display: flex;
                align-items: center;
                label {
                    font-size: 1.2rem;
                }
                /* pay card icon */
                i {
                    font-size: 30px;
                }
                .far {
                    padding-left: 1.5rem;
                }
                /* pay pay pal icon */
                .fab {
                    padding-left: 3.5rem;
                }
            }
        }
        .discount {
            color: #f4911d;
        }
        button {
            background-color: #f4911d;
            color: white;
            border-radius: 0;
            height: 50px;
            width: 300px;
            font-size: large;
            justify-self: flex-end;
        }
    }
    .pay-btn {
        display: flex;
        justify-content: flex-end;
    }
}

/* media querys */

.pay-section {
    table {
        @media(max-width: 500px) {
            font-size: 12px;
        }
    }
    .for-pay {
        @media(max-width: 1000px) {
            width: 100%;
        }
    }
    @media(max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .img {
        @media(max-width: 1000px) {
            margin: 2rem 0;
        }
        img {
            @media(max-width: 500px) {
                width: 450px !important;
            }
        }
    }
}

.pay-section {
    .far {
        @media(max-width: 1000px) {
            padding-left: 1rem !important;
        }
    }
}

.pay-section {
    button {
        @media(max-width: 992px) {
            height: 45px !important;
            width: 250px !important;
        }
        @media(max-width: 500px) {
            height: 40px !important;
            width: 200px !important;
        }
        @media(max-width: 400px) {
            height: 35px !important;
            width: 150px !important;
        }
    }
}

.pay-section {
    h2 {
        @media(max-width: 768px) {
            font-size: 25px;
        }
        @media(max-width: 500px) {
            font-size: 20px;
        }
    }
}

.pay-section {
    .payment-methods {
        @media(max-width: 500px) {
            padding: 1rem !important;
            display: flex;
            justify-content: center;
        }
        div {
            @media(max-width: 500px) {
                margin-left: 1rem;
            }
        }
        i {
            @media(max-width: 500px) {
                font-size: 22px !important;
            }
        }
    }
}

.pay-section {
    label {
        @media(max-width: 768px) {
            font-size: 1rem !important;
        }
    }
}

.uni-numbers {
    .row {
        .col-md-6 {
            margin-top: 2rem !important;
        }
    }
}