.mediaViewInfo {
	--web-view-name: Web 1366 – 1;
	--web-view-id: Web_1366__1;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
:root {
	--web-view-ids: Web_1366__1;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: none;
}
#Web_1366__1 {
	position: relative;
	width: 85.375rem;
	height: 45.25rem;
	background-color: rgba(255,255,255,1);
	overflow: hidden;
	--web-view-name: Web 1366 – 1;
	--web-view-id: Web_1366__1;
	--web-scale-on-resize: true;
	--web-enable-deep-linking: true;
}
#How_We_Work_bi {
	position: absolute;
	width: 1365.284px;
	height: 707px;
	left: 0.716px;
	top: 0px;
	overflow: visible;
	padding: 41px 188px 33px 200px;
}
#bg_bj {
	fill: rgba(255,255,255,1);
}
.bg_bj {
	position: absolute;
	overflow: visible;
	width: 1365.284px;
	height: 707px;
	left: 0px;
	top: 0px;
}
#Ellipse_3_copy_bk {
	fill: rgba(230,230,230,1);
}
.Ellipse_3_copy_bk {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 9px;
	left: 1168.284px;
	top: 353px;
}
#Group_997 {
	position: absolute;
	width: 964.284px;
	height: 633px;
	left: 200px;
	top: 41px;
	overflow: visible;
}
#How_We_Work_bm {
	left: 49.95px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 863.796px;
	height: 74px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: lighter;
	font-size: 30px;
	color: rgba(51,51,51,1);
}
#A_profile_is_created_on_the_of_bn {
	left: 27.652px;
	top: 50px;
	position: absolute;
	overflow: visible;
	width: 917.631px;
	height: 74px;
	line-height: 30px;
	margin-top: -5px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(51,51,51,1);
}
#gray_bo {
	fill: rgba(230,230,230,1);
}
.gray_bo {
	position: absolute;
	overflow: visible;
	width: 866.529px;
	height: 4.526px;
	left: 48.877px;
	top: 373.99px;
}
#Ellipse_3_bp {
	fill: rgba(230,230,230,1);
}
.Ellipse_3_bp {
	position: absolute;
	overflow: visible;
	width: 8.146px;
	height: 8.146px;
	left: 40.73px;
	top: 372.18px;
}
#orange_bq {
	fill: rgba(242,124,102,1);
}
.orange_bq {
	position: absolute;
	overflow: visible;
	width: 291.453px;
	height: 4.526px;
	left: 0px;
	top: 373.99px;
}
#orange_circle_br {
	fill: rgba(242,124,102,1);
}
.orange_circle_br {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 95.943px;
	top: 501.614px;
}
#line_bs {
	fill: rgba(242,124,102,1);
}
.line_bs {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 126.719px;
	left: 143.916px;
	top: 374.896px;
}
#Step_1_bt {
	left: 90.211px;
	top: 315.979px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(242,124,102,1);
	letter-spacing: 0.2px;
}
#Group_995_bu {
	position: absolute;
	width: 271.6px;
	height: 147.938px;
	left: 9.699px;
	top: 179.512px;
	overflow: visible;
}
#Initial_acceptance_offer_lette_bv {
	left: 7.32px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#Within_24_hours_we_begin_worki_bw {
	left: 0px;
	top: 39.648px;
	position: absolute;
	overflow: visible;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#yellow_bx {
	fill: rgba(249,203,143,1);
}
.yellow_bx {
	position: absolute;
	overflow: visible;
	width: 290.547px;
	height: 4.526px;
	left: 224.812px;
	top: 373.99px;
}
#yellow_circle_by {
	fill: rgba(249,203,143,1);
}
.yellow_circle_by {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 314.896px;
	top: 152.232px;
}
#line_bz {
	fill: rgba(249,203,143,1);
}
.line_bz {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 125.813px;
	left: 359.804px;
	top: 248.177px;
}
#Step_2_b {
	left: 308.469px;
	top: 393.579px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(249,203,143,1);
	letter-spacing: 0.2px;
}
#Group_994_b {
	position: absolute;
	width: 271.6px;
	height: 147.938px;
	left: 233.404px;
	top: 470.512px;
	overflow: visible;
}
#Deposit_payment_b {
	left: 7.32px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#In_case_the_student_wishes_to__b {
	left: 0px;
	top: 39.648px;
	position: absolute;
	overflow: hidden;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#yellow_b {
	fill: rgba(249,203,143,1);
}
.yellow_b {
	position: absolute;
	overflow: visible;
	width: 290.547px;
	height: 4.526px;
	left: 673.736px;
	top: 373.99px;
}
#yellow_circle_b {
	fill: rgba(249,203,143,1);
}
.yellow_circle_b {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 769.68px;
	top: 152.232px;
}
#line_b {
	fill: rgba(249,203,143,1);
}
.line_b {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 125.813px;
	left: 817.199px;
	top: 248.177px;
}
#Step_4_b {
	left: 763.494px;
	top: 393.579px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(249,203,143,1);
	letter-spacing: 0.2px;
}
#Group_993_b {
	position: absolute;
	width: 271.6px;
	height: 162.488px;
	left: 682.982px;
	top: 470.512px;
	overflow: visible;
}
#With_the_final_acceptance_lett_b {
	left: 7.321px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#the_student_can_apply_for_a_st_ca {
	left: 0px;
	top: 54.198px;
	position: absolute;
	overflow: visible;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#green_line_cb {
	fill: rgba(238,143,33,1);
}
.green_line_cb {
	overflow: visible;
	position: absolute;
	width: 291px;
	height: 3.88px;
	left: 448.856px;
	top: 374.362px;
	transform: matrix(1,0,0,1,0,0);
}
#green__circle_cc {
	fill: rgba(238,143,33,1);
}
.green__circle_cc {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 551.067px;
	top: 501.614px;
}
#line_cd {
	fill: rgba(238,143,33,1);
}
.line_cd {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 126.719px;
	left: 601.171px;
	top: 374.896px;
}
#Step_3_ce {
	left: 543.941px;
	top: 315.979px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(238,143,33,1);
	letter-spacing: 0.2px;
}
#Group_996_cf {
	position: absolute;
	width: 271.6px;
	height: 147.938px;
	left: 459.279px;
	top: 179.512px;
	overflow: visible;
}
#Final_Acceptance_lette_cg {
	left: 7.32px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#After_submitting_a_copy_of_the_ch {
	left: 0px;
	top: 39.648px;
	position: absolute;
	overflow: visible;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#Rectangle_9_ci {
	fill: rgba(242,124,102,1);
}
.Rectangle_9_ci {
	position: absolute;
	overflow: visible;
	width: 93px;
	height: 2px;
	left: 437.8px;
	top: 40.919px;
}
#monitor {
	position: absolute;
	width: 58px;
	height: 53px;
	left: 790.01px;
	top: 173.919px;
	overflow: visible;
}
#How_We_Work_bi {
	position: absolute;
	width: 1365.284px;
	height: 707px;
	left: 0.716px;
	top: 0px;
	overflow: visible;
	padding: 41px 188px 33px 200px;
}
#bg_bj {
	fill: rgba(255,255,255,1);
}
.bg_bj {
	position: absolute;
	overflow: visible;
	width: 1365.284px;
	height: 707px;
	left: 0px;
	top: 0px;
}
#Ellipse_3_copy_bk {
	fill: rgba(230,230,230,1);
}
.Ellipse_3_copy_bk {
	position: absolute;
	overflow: visible;
	width: 9px;
	height: 9px;
	left: 1168.284px;
	top: 353px;
}
#Group_997_bl {
	position: absolute;
	width: 964.284px;
	height: 633px;
	left: 200px;
	top: 41px;
	overflow: visible;
}
#How_We_Work_bm {
	left: 49.95px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 863.796px;
	height: 74px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: lighter;
	font-size: 30px;
	color: rgba(51,51,51,1);
}
#A_profile_is_created_on_the_of_bn {
	left: 27.652px;
	top: 50px;
	position: absolute;
	overflow: visible;
	width: 917.631px;
	height: 74px;
	line-height: 30px;
	margin-top: -5px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	color: rgba(51,51,51,1);
}
#gray_bo {
	fill: rgba(230,230,230,1);
}
.gray_bo {
	position: absolute;
	overflow: visible;
	width: 866.529px;
	height: 4.526px;
	left: 48.877px;
	top: 373.99px;
}
#Ellipse_3_bp {
	fill: rgba(230,230,230,1);
}
.Ellipse_3_bp {
	position: absolute;
	overflow: visible;
	width: 8.146px;
	height: 8.146px;
	left: 40.73px;
	top: 372.18px;
}
#orange_bq {
	fill: rgba(242,124,102,1);
}
.orange_bq {
	position: absolute;
	overflow: visible;
	width: 291.453px;
	height: 4.526px;
	left: 0px;
	top: 373.99px;
}
#orange_circle_br {
	fill: rgba(242,124,102,1);
}
.orange_circle_br {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 95.943px;
	top: 501.614px;
}
#line_bs {
	fill: rgba(242,124,102,1);
}
.line_bs {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 126.719px;
	left: 143.916px;
	top: 374.896px;
}
#Step_1_bt {
	left: 90.211px;
	top: 315.979px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(242,124,102,1);
	letter-spacing: 0.2px;
}
#Group_995_bu {
	position: absolute;
	width: 271.6px;
	height: 147.938px;
	left: 9.699px;
	top: 179.512px;
	overflow: visible;
}
#Initial_acceptance_offer_lette_bv {
	left: 7.32px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#Within_24_hours_we_begin_worki_bw {
	left: 0px;
	top: 39.648px;
	position: absolute;
	overflow: visible;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#yellow_bx {
	fill: rgba(249,203,143,1);
}
.yellow_bx {
	position: absolute;
	overflow: visible;
	width: 290.547px;
	height: 4.526px;
	left: 224.812px;
	top: 373.99px;
}
#yellow_circle_by {
	fill: rgba(249,203,143,1);
}
.yellow_circle_by {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 314.896px;
	top: 152.232px;
}
#line_bz {
	fill: rgba(249,203,143,1);
}
.line_bz {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 125.813px;
	left: 359.804px;
	top: 248.177px;
}
#Step_2_b {
	left: 308.469px;
	top: 393.579px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(249,203,143,1);
	letter-spacing: 0.2px;
}
#Group_994_b {
	position: absolute;
	width: 271.6px;
	height: 147.938px;
	left: 233.404px;
	top: 470.512px;
	overflow: visible;
}
#Deposit_payment_b {
	left: 7.32px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#In_case_the_student_wishes_to__b {
	left: 0px;
	top: 39.648px;
	position: absolute;
	overflow: hidden;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#yellow_b {
	fill: rgba(249,203,143,1);
}
.yellow_b {
	position: absolute;
	overflow: visible;
	width: 290.547px;
	height: 4.526px;
	left: 673.736px;
	top: 373.99px;
}
#yellow_circle_b {
	fill: rgba(249,203,143,1);
}
.yellow_circle_b {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 769.68px;
	top: 152.232px;
}
#line_b {
	fill: rgba(249,203,143,1);
}
.line_b {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 125.813px;
	left: 817.199px;
	top: 248.177px;
}
#Step_4_b {
	left: 763.494px;
	top: 393.579px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(249,203,143,1);
	letter-spacing: 0.2px;
}
#Group_993_b {
	position: absolute;
	width: 271.6px;
	height: 162.488px;
	left: 682.982px;
	top: 470.512px;
	overflow: visible;
}
#With_the_final_acceptance_lett_b {
	left: 7.321px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#the_student_can_apply_for_a_st_ca {
	left: 0px;
	top: 54.198px;
	position: absolute;
	overflow: visible;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#green_line_cb {
	fill: rgba(238,143,33,1);
}
.green_line_cb {
	overflow: visible;
	position: absolute;
	width: 291px;
	height: 3.88px;
	left: 448.856px;
	top: 374.362px;
	transform: matrix(1,0,0,1,0,0);
}
#green__circle_cc {
	fill: rgba(238,143,33,1);
}
.green__circle_cc {
	position: absolute;
	overflow: visible;
	width: 96.849px;
	height: 96.849px;
	left: 551.067px;
	top: 501.614px;
}
#line_cd {
	fill: rgba(238,143,33,1);
}
.line_cd {
	position: absolute;
	overflow: visible;
	width: 1.81px;
	height: 126.719px;
	left: 601.171px;
	top: 374.896px;
}
#Step_3_ce {
	left: 543.941px;
	top: 315.979px;
	position: absolute;
	overflow: visible;
	width: 118.37px;
	height: 71.7800064086914px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 30px;
	color: rgba(238,143,33,1);
	letter-spacing: 0.2px;
}
#Group_996_cf {
	position: absolute;
	width: 271.6px;
	height: 147.938px;
	left: 459.279px;
	top: 179.512px;
	overflow: visible;
}
#Final_Acceptance_lette_cg {
	left: 7.32px;
	top: 0px;
	position: absolute;
	overflow: visible;
	width: 259.459px;
	height: 65.04383087158203px;
	line-height: 19.97422981262207px;
	margin-top: -0.9987144470214844px;
	text-align: center;
	font-family: Raleway-Medium;
	font-style: normal;
	font-weight: normal;
	font-size: 17.9768009185791px;
	color: rgba(51,51,51,1);
	letter-spacing: 0.2px;
}
#After_submitting_a_copy_of_the_ch {
	left: 0px;
	top: 39.648px;
	position: absolute;
	overflow: visible;
	width: 272.6px;
	height: 108.29021453857422px;
	line-height: 24.9677791595459px;
	margin-top: -4.993554592132568px;
	text-align: center;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	font-size: 14.980669975280762px;
	color: rgba(89,89,89,1);
	letter-spacing: 0.2px;
}
#Rectangle_9_ci {
	fill: rgba(242,124,102,1);
}
.Rectangle_9_ci {
	position: absolute;
	overflow: visible;
	width: 93px;
	height: 2px;
	left: 437.8px;
	top: 40.919px;
}
#email-01 {
	position: absolute;
	width: 52px;
	height: 52px;
	left: 992px;
	top: 215px;
	overflow: visible;
}
#srfdgthbnj-01 {
	position: absolute;
	width: 70px;
	height: 92px;
	left: 313px;
	top: 545px;
	overflow: visible;
}
#receipt-01 {
	position: absolute;
	width: 82px;
	height: 106px;
	left: 524px;
	top: 188px;
	overflow: visible;
}
#clipboard-01 {
	position: absolute;
	width: 63px;
	height: 69px;
	left: 773px;
	top: 557px;
	overflow: visible;
}