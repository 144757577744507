/* Global */
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@700&family=Lexend:wght@700&family=Tajawal:wght@500&display=swap');

html {
  width: 100%;
  font-family: Tajawal 'sans-serif';
}

body {
  font-family: Tajawal 'sans-serif' !important;

}

p {
  white-space: pre-line;
}

.container-d {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-d-ar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ar {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0;
}
.ar-margin {
  margin-right: 3rem;
}
.icon-cont {
  margin-right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}
.icon-cont img {
  width: 60px;
}
.icon-cont p {
  text-align: center;
  margin-top: 0.3rem;
  color: #e68f24;
}
.context-cont h4 {
  max-width: 400px;
  font-size: 18px;
  text-transform: initial;
  color: #4e4e4f;
}
.context-cont span {
  color: #e68f24;
}

@media (max-width: 768px) {
  .container-d {
      flex-direction: column;
      text-align: center;
  }
  .container-d-ar {
      flex-direction: column-reverse !important;
      text-align: center;
  }
  .icon-cont {
      margin-right: 0;
  }
  .ar {
      text-align: center;
      font-size: 16px;
  }
  .ar-margin {
      margin-right: 0rem;
  }
}

body {
  background: url('./assets/images/website-artboard.png') repeat-y !important;
  /* opacity: 50%; */
  min-width: 98vw;
  max-width: 100vw !important;
}

.ar-text {
  font-family: 'Tajawal', sans-serif;
}

.ar-text2 {
  font-family: 'Tajawal', sans-serif;
}

.wp_btn {
  position: fixed;
  display: block;
  right: 15px;
  bottom: 500px;
  z-index: 99999;
  text-decoration: none;
}

.wp_btn i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #4bc658;
  color: #fff;
  transition: all 0.4s;
}

.wp_btn i:hover {
  background: white;
  color: #111;
}

.w-80 {
  width: 80% !important;
}
.nav-item {
  margin-right: 42px !important;
}

.nav-item-ar {
  margin-right: 65px !important;
}

.store-btn {
  border-radius: 10px;
}

.store-btn:hover {
  color: #000000 !important;
}

.ar-label {
  width: 33% !important;
}

.nav-linkar {
  font-size: 18px;
}

.nav-linkar:hover {
  color: #eb8c1e !important;
}

.nav-link:hover {
  color: #000000 !important;
}

.welcome-header {
  background-color: #eb8c1e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5%;
}

.welcome-header h5 {
  color: #ffffff;
  align-self: center;
  margin-top: 5px;
}

/* Hero Carousel */
.carousel-item img {
  filter: brightness(50%);
}

.headline {
  background-color: rgba(255, 255, 255, 0.8);
  color: rgb(246, 146, 30);
  width: 51%;
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.carousel-caption p {
  width: 50%;
}

.hero-learn-more {
  background-color: rgb(246, 146, 30);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 20px;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
}

.hero-text-container {
  position: absolute;
  top: 72%;
}

/* Famous Universities Section */

.famous-unis {
  margin-top: 100px;
  /* background-image: url("./assets/images/Rectangle.png"); */
}

.famous-unis .row {
 display: flex;
 justify-content: space-evenly;
 margin-top: 40px;
 align-items: center;
}

.famous-unis .row div {
  display: flex;
  width: 250px;
  height: 150px;
  border: 0.5px solid #f6921e;
  border-radius: 20px;
  padding: 5px;
  margin: 1px;
}

.famous-unis .row div a {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.famous-unis .row div a img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* Services Section */
.services-section {
  display: flex;
  flex-direction: column;
  background-image: url("./assets/images/Rectangle.png");
  margin: 50px 0;
}

.services-section h2 {
  text-align: center;
}

.services-section .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-card {
  margin: 40px auto;
}

.illustration {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.illustration img {
  width: 5rem;
  z-index: 2;
}

.illustration p {
  z-index: 3;
  text-align: center;
  /* width: 100%; */
  font-size: 14px;
}

.book-price {
  width: 50% !important;
}

.book-price2 {
  width: 53% !important;
}

.circle {
  width: 15rem;
  height: 15rem;
  background-image: url("./assets/images/Services/circle.png");
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
}

.services-learn-more {
  background: transparent;
  border: 0.5px solid #f6921e;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 10px 20px;
  text-decoration: none;
  color: #000000;
  font-size: 18px;
}

.services-learn-more:hover {
  background: #eb8c1e;
  color: #ffffff;
}

.accordion-background {
  background-color: #ffffff;
}

.accordion-button:focus {
  background-color: #f9cb8f !important;
}
/* Counter Section */
.counter-section {
  background-color: #f9cb8f;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.counter-section .row div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.counter-section p {
  text-align: center;
  color: #ffffff;
}

/* How We Work */
.how-we-work2 {
  display: none;
}

.how-we-work3 {
  display: none;
}

.rectangle {
  position: absolute;
  background-color: #f9cb8f;
  right: 50;
  height: 377px;
  width: 3px;
  /* margin-top: 1%; */
  margin-left: 2%;
}

.rectanglear {
  position: absolute;
  background-color: #f9cb8f;
  right: 50;
  height: 218px;
  width: 3px;
  /* margin-top: 1%; */
  margin-left: 2%;
}

.rectangle2 {
  position: absolute;
  background-color: #f27c66;
  height: 288px;
  width: 3px;
  margin-top: -291px;
  margin-left: 40px;
}

.rectangle2ar {
  position: absolute;
  background-color: #f27c66;
  height: 166px;
  width: 3px;
  margin-top: -170px;
  margin-left: 40px;
}


.rectangle3 {
  position: absolute;
  background-color: #f27c66;
  height: 288px;
  width: 3px;
  margin-top: -291px;
  margin-left: 40px;
}

.rectangle3ar {
  position: absolute;
  background-color: #f27c66;
  height: 166px;
  width: 3px;
  margin-top: -170px;
  margin-left: 40px;
}

.steps-title {
  font-weight: 800;
}

/* What We Offer Section */

.offer-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer-column h2 {
  align-self: start;
}

/* Success Stories Section */

.success-section {
  margin-top: 100px;
}

.success-section .row .col-md-6 {
  margin-top: 100px;
}

.rectangle-115 {
  background-color: #ec8f19;
  height: 3px;
  width: 95px;
}

.success-section .row .col-md-6 h2::after {
  content: "";
  border-bottom:1px solid rgb(246, 146, 30);
}

/* University Owl Carouse */
.slider-image {
  object-fit: contain;
  max-width: 200px;
  max-height: 80px;
  min-height: 39px;
  min-width: 99px;
}
@media (min-width: 300px) {
  .slider-image {
    max-width: 197px !important;
    max-height: 80px !important;
  }
}

.owl-carousel-background {
  background-color: #ffffff;
}

.swiper-container-mobile {
  width: 75% !important;
}

/* Footer */

.footer {
  padding-bottom: 20px;
}

.footer .col-md-4 {
  margin-top: 50px;
}

.footer h4 {
  font-weight: 800;
  letter-spacing: 1px;
}

.footer-data, .footer-data a {
  font-size: 14px;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
}

.footer-data, .footer-data a:hover {
  color: #eb8c1e;
}

.footer-links {
  display: flex;
  justify-content: start;
}

.footer-links a {
  background-color: #7a7a7a;
  padding: 5px 10px;
  margin: 0 10px;
  border: 0.5px solid #7a7a7a;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.footer-links a {
  color: #ffffff;
}


.footer-links a i:hover {
  color: orange;
}

.second-footer {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.second-footer p {
  margin: 0;
}

.footer-nav {
  display: flex;
  align-items: center;
}

.footer-nav-item {
  font-size: 14px;
  margin: 0 10px;
  color: #b2b2b2;
  transition: all 0.5s ease-in-out;
}

.footer-nav-item:hover {
  color: #f4911d;
}

.footer-nav-item::before {
  content: "";
  border-left: 1px solid #ffffff;
  margin: 0 10px;
}

.google-map {
  width: 100%;
  height: 100%;
}

/* Services Page */
.services-row {
  margin-bottom: 100px;
  background-color: #ffffff;
}

.services-row .col-md-3 {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-right: 1px;
  margin-top: 50px;

  border: 0.5px solid #f9f9f9;
  border-top-left-radius: 75px;
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;

  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
  justify-content: space-evenly;
}

.w-20 {
  width: 20% !important;
}

.services-row .col-md-3 img {
  max-height: 85px;
  max-width: 85px;
}

.services-row .col-md-3 h4 {
  color: #f69628;
  letter-spacing: 1px;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
}

.services-row .col-md-3 p {
  font-size: 14px;
  text-align: center;
}

/* Registration Steps Page */
.registration-steps-band {
  margin-top: 50px;
  display: inherit;
  background-color: #f9cb8f;
  padding: 50px 0;
}

.world-map {
  background-image: url("./assets/images/RegistrationSteps/world.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.columns-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.columns-container img {
  margin: 0 10px;
}

.footer-logo {
  max-width: 300px;
  max-height: 80px;
}

.navbar-brand img {
  max-width: 238px;
  max-height: 80px;
}

/* Contact Page */

.contact-form input  {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  /* padding: 10px;*/
  margin: 0 10px; 
}

.contact-form textarea {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  margin: 0 10px; 
}

.btn-contact {
  padding: 10px;
  background-color: #eb8c1e;
	color: #fffffF;
	transition: all 0.5s;
	position: relative;
  border-radius: 10px;
}
.btn-contact::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: rgba(255,255,255,0.1);
	transition: all 0.3s;
}
.btn-contact:hover::before {
	opacity: 0 ;
	transform: scale(0.5,0.5);
}
.btn-contact::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	opacity: 0;
	transition: all 0.3s;
	border: 1px solid #eb8c1e;
  border-radius: 10px;
	transform: scale(1.2,1.2);
}
.btn-contact:hover::after {
	opacity: 1;
	transform: scale(1,1);
}

.contact-card-row1 {
  flex-direction: row-reverse;
  margin-bottom: 100px;
}

.contact-card-col1 {
  position: relative;
  display: flex;
  flex-direction: column;
}

.contact-card-row2 {
  background-color: #f9cb8f;
  max-height: 500px !important;
  min-height: 212px !important;
  position: absolute;
  width: 80%;
  bottom: -52px;
  left: 21%;
}

.contact-card-col2 {
  margin-left: -154px;
  margin-top: 18px !important;
}

.contact-card-fluid2 {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-left: -12px !important;
}

/* Register Application Page */
.application-container-hero {
  display: flex;
  align-items: center;
  justify-content: center;
}

.application-container {
  width: fit-content;
  background-color: #fdebd4;
  border-top: 1px solid #f69321;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.application-container .container {
  display: flex;
  justify-content: center;
}

.application-container .container input {
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding: 10px 50px;
  margin: 10px 50px;
}

@media (max-width: 986px) {
  .application-container .container input {
    padding: 10px;
    margin: 0;
    margin: 3px;
  }
}

.application-container .container input:hover {
  outline: none;
  cursor: pointer;
  border: 1px solid #f27c66;
}

.application-container .container input:focus {
  outline: none;
  border: 1px solid #eb8c1e;
}

.applicaiton2 .container {
  display: flex;
  justify-content: space-between;
}

.applicaiton2 .container label {
  text-align: left;
  justify-self: start;
  align-self: center;
}

.register-btn {
  padding: 10px 40px;
  justify-self: start;
  background: #f6921e;
  border-radius: 10px;
  color: #Ffffff;
  margin-bottom: 20px;
}

.register-btn:hover {
  color: #000000;
  background-color: #ffffff;
}

/* Universities Filter Page */

.unversities-container {
  display: flex;
  /* justify-content: center; */
}

.universities .row .col-md-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #f79b31;
  padding: 5px;
}

.universities .row .col-md-3 img {
  max-width: 200px;
  max-height: 100px;
}

.universities .row .col-md-3 p {
  text-align: left;
}

.universities .row .col-md-3 button {
  border: 1px solid #f79b31;
  background-color: #f79b31;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
}

.universities .row .col-md-6 button {
  border: 1px solid #f79b31;
  background-color: #f79b31;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
}

.universities .row .col-md-6 button:hover {
  background-color: #7a7a7a;
  color: #f79b31;
  border: 1px solid #f79b31;
}

.filter_select {
  position: sticky;
  border: 1px solid #7a7a7a;
  border-radius: 10px;
  padding: 10px;
  margin: 15px;
  width: 85% !important;
}

.filter_select:focus {
  border: 1px solid #f69321;
}

.result-row {
  margin: 50px 0;
  /* border-top: 1px solid #f69321; */
}

/* .result-row div {
  display: flex;
} */

/* Universities Page */

.uni-container {
  display: flex;
  margin-top: 100px;
}

.uni-container .uin-row {
  display: flex;
  justify-content: center;
}

.uni-container .row .col-md-3 {
  background-color: #ffffff;
  padding: 0;
  margin: 5px 10px;
  border: 1px solid #f69321;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.uni-container .row .col-md-3 img {
  margin: 5px 0;
  max-height: 100px;
  max-width: 200px;
}

.uni-container .row .col-md-3 button {
  border: 1px solid #f79b31;
  background-color: #f79b31;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0;
}

.filter-row {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
}

.filter-row .col-md-4 {
  margin: 20px 0;
}

/* University Page */

.counter1 p {
  /* style={{position: "absolute", top: "105%", left: "24%"}} */
  position: absolute;
  top: 115%;
  left: 24%;
  color: #ec8f19;
}

.counter2 p {
  /* style={{position: "absolute", top: "105%", left: "24%"}} */
  position: absolute;
  top: 115%;
  left: 46%;
  color: #ec8f19;
}

.counter3 p {
  /* style={{position: "absolute", top: "105%", left: "24%"}} */
  position: absolute;
  top: 115%;
  left: 69%;
  color: #ec8f19;
}

.counter1 .ranking {
  position: absolute;
  top: 110%;
  left: 24%;
  color: #000000;
}

.counter2 .ranking {
  position: absolute;
  top: 110%;
  left: 46%;
  color: #000000;
}

.counter3 .ranking {
  position: absolute;
  top: 110%;
  left: 69%;
  color: #000000;
}

.university-container {
 margin: 100px 0;
}

.university-container .row .col-md-5 img {
  border: 1px solid #f79b31;
  border-radius: 20px;
}

.university-container .row .col-md-5 {
  display: flex;
  flex-direction: column;
}

.university-container .row .col-md-5 button a {
  color: #ffffff;
}

.university-container .row .col-md-5 button {
  align-self: center;
  border: 1px solid #f79b31;
  background-color: #f79b31;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.university-container .row .col-md-5 button:hover {
  border: 1px solid #aeaeaf;
  background-color: #ffffff;
}

.university-container .row .col-md-5 button:hover a {
  color: #f79b31;
}

.university-counter {
  border: 1px solid black;
  margin: 50px 0;
  padding: 20px 0;
}

.university-container .container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.university-counter .container div {
  display: inline-flex;
}

.university-counter .container img {
  margin: 0 20px;
}

.uni-numbers .row .col-md-5 {
  border-radius: 20px;
}

.uni-numbers .row .col-md-5 .swipe {
  display: flex;
  justify-content: center;
}

.uni-numbers .row .col-md-5 img {
  max-height: 700px;
  max-width: 400px;
  object-fit: contain;
}

/* input.form-control.ar::placeholder {
  text-align: end;
} */

/* textarea.form-control.ar::placeholder {
  text-align: end;
} */

/* .form-control2.ar::placeholder {
  text-align: end;
}

textarea.form-control2.ar::placeholder {
  text-align: end;
} */

.form-control.ar {
  direction: rtl;
}

.accordion-button {
  background-color: #ffffff !important;
}

.arAccordion .accordion-button {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.arAccordion .accordion-button::after {
  margin-left: 0;
}

/* .form-control2.ar, input[type="date"]:before{
  color:lightgray;
  content:attr(placeholder);
} */

/* Store Books Page */
.store-hero {
  background-image: url("./assets/images/Store/hero.jpg");
  width: 100vw;
  height: 50vh;
}

.book-price {
  background-color: #f4911d;
  color: #ffffff;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  font-weight: 900;
}

.book-price2 {
  background-color: #f4911d;
  color: #ffffff;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  font-weight: 900;
}

.books-filter {
  border-bottom: 1px solid #f4911d;
}

.books-filter::placeholder {
  color: #f4911d;
}

.books-filter-ar {
  border-bottom: 1px solid #f4911d;
  direction: rtl !important;
  text-align: right;
}

.books-filter-ar::placeholder {
  color: #f4911d;
  text-align: right;
}

.products-aside {
  width: 40%;
  display: flex;
  flex-direction: column;
}

.book-aside {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
}

.book-aside img {
  height: 50%;
  width: 50%;
}

.banner-books {
  background-color: #f9cb8f;
  /* display: flex;
  justify-content: center; */
}

/* .books-filter-input input[type=text]:focus {

} */

.books-filter:focus {
  outline: none;
}

/* Profile Page */
.profile-aside {
  height: 100vh;
  background-color: #f4911d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-image {
  display: flex;
  align-items: center;
}

.profile-image p {
  margin-left: 50px;
}

.profile-image img {
  max-width: 25%;
}

.degree-filter-university {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
}

.degree-filter-university p button {
  background-color: #f27c66;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin: 0 5px;
}

.degree-filter-university p:hover button {
  background-color: #ffffff;
  color: #000000;
}

.books-filter-aside {
  flex-direction: column;
}

.books-filter-aside-ar {
  flex-direction: column;
  width: 62%;
}

.book-image {
  max-width: 100%;
  max-height: 100%;
}

.special-offer-carousel {
  /* style={{display: 'flex', justifyContent: 'center', alignItems: "start", flexDirection: "column"}} */
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
}

.special-offer-carousel-ar {
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
}

.special-offer-carousel-image {
  max-height: 300px;
}

.ar-modal .modal-header button {
  margin: 0
}

.ar-input {
  direction: rtl;
}

.ar-input::placeholder {
  text-align: right;
}

.services-section-mobile-version {
  display: none !important;
}

.language-filter {
  position: absolute !important;
  top: 11% !important;
  /* margin-left: 7% !important; */
}

.university-filter {
  position: absolute !important;
  top: 27% !important;
  /* margin-left: 7%; */
}

.major-filter {
  position: absolute !important;
  top: 43% !important; 
  /* margin-left: 7%; */
}

.degree-filter {
  position: absolute !important;
  top: 58% !important;
  /* margin-left: 7%; */
}

.filter-logo-img{
  /* style={{maxWidth: "150px", maxHeight: "100px", objectFit: "contain"}} */
  max-width: 150px;
  max-height: 100px;
  
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.hero-image {
  /* style={{maxHeight: "650px", minHeight: "649px"}} */
  max-height: 100%;
  min-height: 649px;
}

.promo-code {
  border: 1px solid black;
  border-radius: 5px;
  padding: 5px;
  width: 80% !important;
}

.books-filter-input .products-aside {
  width: 100% !important;
}

/* Media queries */
@media only screen and (max-width: 1500px ){
  .contact-card-col2 {
    margin-left: -154px;
    width: 60% !important;
    margin-top: 18px !important;
  }

  .counter1 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 115%;
    left: 15%;
    color: #ec8f19;
  }

  .counter2 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 115%;
    left: 46%;
    color: #ec8f19;
  }
  
  .counter3 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 115%;
    left: 75%;
    color: #ec8f19;
  }
  
  .counter1 .ranking {
    position: absolute;
    top: 110%;
    left: 15%;
    color: #000000;
  }
  
  .counter2 .ranking {
    position: absolute;
    top: 110%;
    left: 46%;
    color: #000000;
  }
  
  .counter3 .ranking {
    position: absolute;
    top: 110%;
    left: 75%;
    color: #000000;
  }
}

@media only screen and (max-width: 1430px ) {
  .contact-card-col2 {
    width: 65% !important;
  }

  .contact-card-fluid2 {
    margin-left: 42px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .nav-item.nav-item-ar {
    margin-right: 42px !important;
  }
  .hero-image {
    min-height: 150px;
    max-height: 350px;
  }

  .book-price {
    width: 60% !important;
  }

  .counter1 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 18%;
    color: #ec8f19;
    font-size: 14px;
  }

  .counter2 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 46%;
    color: #ec8f19;
    font-size: 14px;
  }
  
  .counter3 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 75%;
    color: #ec8f19;
    font-size: 14px;
  }
  
  .counter1 .ranking {
    position: absolute;
    top: 107%;
    left: 18%;
    color: #000000;
  }
  
  .counter2 .ranking {
    position: absolute;
    top: 107%;
    left: 46%;
    color: #000000;
  }
  
  .counter3 .ranking {
    position: absolute;
    top: 107%;
    left: 75%;
    color: #000000;
  }

  .counter1 img, .counter2 img, .counter3 img {
    width: 80%;
  }

  .rectangle {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 402px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 2%;
  }

  .rectangle2 {
    position: absolute;
    background-color: #f27c66;
    height: 303px;
    width: 3px;
    margin-top: -307px;
    margin-left: 40px;
  }
  
  .rectangle3 {
    position: absolute;
    background-color: #f27c66;
    height: 303px;
    width: 3px;
    margin-top: -307px;
    margin-left: 40px;
  }

  .columns-container {
    width: 50% !important;
  }

  .rectanglear {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 189px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 3%;
  }

  .rectangle2ar {
    position: absolute;
    background-color: #f27c66;
    height: 161px;
    width: 3px;
    margin-top: -165px;
    margin-left: 40px;
  }
  
  .rectangle3ar {
    position: absolute;
    background-color: #f27c66;
    height: 160px;
    width: 3px;
    margin-top: -164px;
    margin-left: 40px;
  }


  .step2ar-1400 {
    margin-top: 75px !important;
  }

  .nav-item {
    margin: 0 !important;
  }

}

@media (max-width: 1400px) and (min-width: 1001px) {
  .slider-image {
    object-fit: contain;
    max-width: 125px;
    max-height: 80px;
  }
}

@media only screen and (max-width: 1300px) {
  .contact-card-col2 {
    width: 70% !important;
  }

  .contact-card-col2 p {
    font-size: 12px !important;
  }
}

@media (max-width: 1400px) and (min-width: 990px) {
  .columns-container {
    width: 35% !important;
  }

  
}

@media only screen and (max-width: 1200px) {

  .book-price {
    width: 80% !important;
    margin-right: 23% !important;
  }

  .hero-image {
    min-height: 300px;
    max-height: 450px;
  }

  .rectangle {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 401px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 4%;
  }

  .rectangle2 {
    position: absolute;
    background-color: #f27c66;
    height: 303px;
    width: 3px;
    margin-top: -307px;
    margin-left: 40px;
  }
  
  .rectangle3 {
    position: absolute;
    background-color: #f27c66;
    height: 303px;
    width: 3px;
    margin-top: -307px;
    margin-left: 40px;
  }

  .rectanglear {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 206px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 3%;
  }

  .rectangle2ar {
    position: absolute;
    background-color: #f27c66;
    height: 153px;
    width: 3px;
    margin-top: -157px;
    margin-left: 40px;
  }
  
  .rectangle3ar {
    position: absolute;
    background-color: #f27c66;
    height: 175px;
    width: 3px;
    margin-top: -179px;
    margin-left: 40px;
  }
  .contact-card-col2 {
    width: 75% !important;
  }

  .contact-card-fluid2 {
    margin-left: 60px !important;
  }

  .special-offer-carousel {
    align-items: center;
  }

  .services-section .container{
    margin-top: 20px;
  }

  .services-section .container .service-card {
    margin: 50px 0;
  }
  

  .counter1 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 19%;
    color: #ec8f19;
    font-size: 12px;
  }

  .counter2 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 46%;
    color: #ec8f19;
    font-size: 12px;
  }
  
  .counter3 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 75%;
    color: #ec8f19;
    font-size: 12px;
  }
  
  .counter1 .ranking {
    position: absolute;
    top: 107%;
    left: 19%;
    color: #000000;
  }
  
  .counter2 .ranking {
    position: absolute;
    top: 107%;
    left: 46%;
    color: #000000;
  }
  
  .counter3 .ranking {
    position: absolute;
    top: 107%;
    left: 75%;
    color: #000000;
  }

  .counter1.counterar p {
    position: absolute;
    top: 100%;
    left: 19%;
    color: #ec8f19;
    font-size: 12px;
  }

  .counter2.counterar p {
    position: absolute;
    top: 100%;
    left: 46%;
    color: #ec8f19;
    font-size: 12px;
  }

  .counter3.counterar p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 100%;
    left: 75%;
    color: #ec8f19;
    font-size: 12px;
  }

  .counter1.counterar .ranking {
    position: absolute;
    top: 103%;
    left: 19%;
    color: #000000;
  }

  .counter2.counterar .ranking {
    position: absolute;
    top: 103%;
    left: 46%;
    color: #000000;
    font-size: 12px;
  }

  .counter3.counterar .ranking {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 103%;
    left: 75%;
    color: #000000;
    font-size: 12px;
  }

}

@media only screen and (max-width: 1130px) {
  .contact-card-col2 {
    width: 80% !important;
  }
}

@media only screen and (max-width: 1100px) {
  .counter1 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 16%;
    color: #ec8f19;
    font-size: 12px;
  }

  .counter2 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 46%;
    color: #ec8f19;
    font-size: 12px;
  }
  
  .counter3 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 109%;
    left: 75%;
    color: #ec8f19;
    font-size: 12px;
  }
  
  .counter1 .ranking {
    position: absolute;
    top: 107%;
    left: 16%;
    color: #000000;
  }
  
  .counter2 .ranking {
    position: absolute;
    top: 107%;
    left: 46%;
    color: #000000;
  }
  
  .counter3 .ranking {
    position: absolute;
    top: 107%;
    left: 75%;
    color: #000000;
  }
}

@media only screen and (max-width: 1000px) {

  .book-price {
    width: 30% !important;
    margin-right: 3% !important;
  }
  .uni-container .row .col-md-3 img {
    max-width: 150px;
    max-height: 75px;
  }

  .success-stories-page {
    display: flex;
    flex-direction: column;
  }

  .hero-text-container {
    position: absolute;
    top: 100%;
  }

  .contact-card-fluid1 {
    margin-top: 200px;
  }

  .books-row {
    flex-direction: column !important;
    margin: 0 !important;
  }

  .books-row .col-md-3 {
    width: 75% !important;
    margin: 20px 0 !important;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .uni-container .row .col-md-3 {
    width: 40%;
  }
}

@media (max-width: 1000px) and (min-width: 770px){
  .contact-card-col2 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 990px) {

  .counter1 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 130%;
    left: 22%;
    color: #ec8f19;
    font-size: 10px;
  }

  .counter2 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 130%;
    left: 46%;
    color: #ec8f19;
    font-size: 12px;
  }
  
  .counter3 p {
    /* style={{position: "absolute", top: "105%", left: "24%"}} */
    position: absolute;
    top: 130%;
    left: 70%;
    color: #ec8f19;
    font-size: 12px;
  }
  
  .counter1 .ranking {
    position: absolute;
    top: 128%;
    left: 22%;
    color: #000000;
  }
  
  .counter2 .ranking {
    position: absolute;
    top: 128%;
    left: 46%;
    color: #000000;
  }
  
  .counter3 .ranking {
    position: absolute;
    top: 128%;
    left: 70%;
    color: #000000;
  }

  .services-page-container {
    width: 100% !important;
    max-width: 100% !important;
  }

  .services-section .pc-container {
    display: none;
  }

  .services-section-mobile-version {
    display: flex !important;
    justify-content: space-evenly !important;
  }
  .rectangle {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 554px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 4%;
  }

  .rectangle2 {
    position: absolute;
    background-color: #f27c66;
    height: 430px;
    width: 3px;
    margin-top: -435px;
    margin-left: 40px;
  }
  
  .rectangle3 {
    position: absolute;
    background-color: #f27c66;
    height: 430px;
    width: 3px;
    margin-top: -435px;
    margin-left: 40px;
  }

  .rectanglear {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 243px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 4%;
  }

  .rectangle2ar {
    position: absolute;
    background-color: #f27c66;
    height: 165px;
    width: 3px;
    margin-top: -170px;
    margin-left: 40px;
  }
  
  .rectangle3ar {
    position: absolute;
    background-color: #f27c66;
    height: 189px;
    width: 3px;
    margin-top: -193px;
    margin-left: 40px;
  }

  .step3-1400 {
    margin-top: 148px !important;
  }

  .step2ar-1400 {
    margin-top: 120px !important;
  }

  .columns-container {
    flex-direction: column;
  }

  .products-aside .col-md-6 {
    width: 100% !important;
  }

  .special-offer-carousel-image {
    max-height: 150px;
  }

  .special-offer-carousel-h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 990px) {
  .university-counter {
    display: none;
  }
}

@media only screen and (max-width: 768px) {

  .ar-column {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .profile-aside {
    height: 20vh;
    background-color: #f4911d;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .welcome-provada-banner {
    display: none;
  }

  .how-we-work {
    display: none;
  }

  .how-we-work2 {
    display: flex;
    flex-direction: column;
  }

  .how-we-work2-container {
    display: flex;
  }

  .how-we-work3 {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .how-we-work3-container {
    display: flex;
  }

  .rectangle-mob {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 170px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 2%;
  }

  .rectangle2-mob {
    position: absolute;
    background-color: #f27c66;
    height: 3px;
    width: 90vw;
    margin-top: 155px;
  }

  .rectangle2-mob2 {
    position: absolute;
    background-color: #f27c66;
    height: 3px;
    width: 44vw;
    margin-top: 155px;
  }

  .rectangle3-mob {
    position: absolute;
    background-color: #f27c66;
    height: 150px;
    width: 3px;
    margin-top: 155px;
    margin-left: 100px;
  }

  .rectangle3-mob2 {
    position: absolute;
    background-color: #f27c66;
    height: 150px;
    width: 3px;
    margin-top: 155px;
    margin-left: 180px;
  }

  .slider-image {
    object-fit: fill;
    max-width: 100px;
    max-height: 40px;
  }

  .second-footer-container {
    display: none;
  }

  .columns-container {
    display: flex;
    flex-direction: column;
  }

  .columns-container img {
    margin: 50px 0;
    max-width: 500px;
    max-height: 500px;
  }

  /* Services */
  .services-section .container {
    flex-direction: column;
  }

  .services-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
  }

  .services-row .col-md-3 {
    width: 50% !important;
  }

  .uni-container {
    display: flex;
    flex-direction: column;
  }

  .uni-container.ar-uni-container {
    flex-direction: column-reverse;
  }

  .uni-container .filter {
    display: flex;
    justify-content: center;
  }

  .uni-container .filter .filter-row {
    flex-direction: column;
    width: 50%;
  }

  .uni-container .filter .filter-row select {
    width: 50%;
    align-self: center;
  }

  .application-container .container {
    flex-direction: column;
  }

  .hero-text-container {
    position: absolute;
    top: 115%;
  }

  .headline {
    padding: 10px;
    width: 100%;
  }

  .books-row {
    flex-direction: row !important;
  }
   
  .books-row .col-md-3 {
    width: 86% !important;
    margin: 20px 0 !important;
  }

  .books-filter-input {
    display: flex !important;
    justify-content: center;
  }

  .books-filter-aside {
    width: 100%;
    align-items: center;
    margin-bottom: 50px;
  }

  .books-filter-aside p {
    text-align: center;
  }

  .book-image {
    max-height: 50px;
    max-width: 50px;
  }

  .products-aside {
    display: none !important;
  }

  .products-aside .col-md {
    display: flex;
    justify-content: end;
  }

  .products-title-aside {
    text-align: center;
  }

  .special-offer-carousel-col-4 {
    display: flex;
    justify-content: center;
  }
}

/*  .uni-container .filter .filter-row {
    flex-direction: column;
    width: 50%;
  } */
  @media only screen and (max-width: 530px) {
    .uni-container .filter .filter-row {
      flex-direction: column;
      width: 75%;
    }

    .uni-container .filter .filter-row .filter_select {
      width: 70% !important;
    }
  }

@media only screen and (max-width: 490px) {

  .faq-container.w-50 {
    width: 100% !important;
  }

  .hero-image {min-height: 190px;}

  .profile-header {
    flex-direction: column;
  }

  .how-we-work {
    display: none;
  }

  .how-we-work2 {
    display: flex;
    flex-direction: column;
  }

  .how-we-work2-container {
    display: flex;
  }

  .how-we-work3 {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .how-we-work3-container {
    display: flex;
  }

  .rectangle-mob {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 170px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 2%;
  }

  .rectangle2-mob {
    position: absolute;
    background-color: #f27c66;
    height: 3px;
    width: 90vw;
    margin-top: 155px;
  }

  .rectangle2-mob2 {
    position: absolute;
    background-color: #f27c66;
    height: 3px;
    width: 44vw;
    margin-top: 155px;
  }

  .rectangle3-mob {
    position: absolute;
    background-color: #f27c66;
    height: 150px;
    width: 3px;
    margin-top: 155px;
    margin-left: 100px;
  }

  .rectangle3-mob2 {
    position: absolute;
    background-color: #f27c66;
    height: 150px;
    width: 3px;
    margin-top: 155px;
    margin-left: 180px;
  }

  .step1-mob {
    margin-left: 158px;
  }

  .step2-mob {
    margin-right: 186px !important;
  }

  .hero-text-container {
    position: absolute;
    top: 115%;
  }

  .headline {
    padding: 5px;
    width: 80%;
    font-size: 14px !important;
  }

  .hero-text-container .text-start p {
    font-size: 12px !important;
  }

  .columns-container {
    width: 75% !important;
  }

  .contact-card-col2 {
    width: 100% !important;
  }
}

@media only screen and (max-width: 375px) {

  .h4-step3ar {
    margin-right: 165px !important;
  }

  .services-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 !important;
    margin: 0 0 100px !important;
  }

  .services-row .col-md-3 {
    width: 100% !important;
  }

  .rectangle-mob {
    position: absolute;
    background-color: #f9cb8f;
    right: 50;
    height: 170px;
    width: 3px;
    /* margin-top: 1%; */
    margin-left: 2%;
  }

  .rectangle2-mob {
    position: absolute;
    background-color: #f27c66;
    height: 3px;
    width: 90vw;
    margin-top: 155px;
  }

  .rectangle2-mob2 {
    position: absolute;
    background-color: #f27c66;
    height: 3px;
    width: 90vw;
    margin-top: 155px;
  }

  .rectangle3-mob {
    position: absolute;
    background-color: #f27c66;
    height: 150px;
    width: 3px;
    margin-top: 155px;
    margin-left: 100px;
  }

  .rectangle3-mob2 {
    position: absolute;
    background-color: #f27c66;
    height: 150px;
    width: 3px;
    margin-top: 155px;
    margin-left: 180px;
  }

  .step1-mob {
    font-size: 13px;
    margin-left: 158px;
  }

  .step2-mob {
    font-size: 13px;
    margin-right: 175px !important;
  }

  .step3-mob {
    font-size: 13px;
  }

  .hero-text-container {
    position: absolute;
    top: 120%;
  }

  .headline {
    padding: 3px;
    width: 100%;
    font-size: 12px !important;
  }

  .hero-text-container .text-start p {
    font-size: 10px !important;
  }

  .contact-card-fluid2 {
    margin-left: 100px !important;
  }

  .book-price {
    width: 50% !important;
  }

  .swiper-container-mobile {
    width: 100% !important;
  }
}

.card-img, .card-img-bottom, .card-img-top {
  max-height: 250px;
}

@media (max-width: 991px) {
  .nav-ul-v2-changes {
    text-align: center;
  }
  .nav-lang-btn-v2-changes {
    width: 100%;
    margin: auto;
  }
  .nav-lang-btn-v2-changes a {
    padding: 0.5rem 5rem !important;
  }
  .nav-ul-v2-changes-ar {
    text-align: center;
    margin-right: 0 !important;
    flex-direction: column-reverse !important;
  }
  .nav-item.nav-item-ar {
    margin-right: 0px !important;
  }
  .nav-ul-v2-changes-ar li {
    margin-right: 0px !important;
  }
  .collapse-nav-v2-ar button {
    width: 100%;
    margin: auto;
  }
  .collapse-nav-v2-ar button a {
    padding: 0.5rem 5rem !important;
  }
  .text-ar {
    direction: rtl !important;
  }
}



.welcome-header {
  height: 2rem !important;
}
.welcome-header h5 {
  margin-bottom: 0.25rem !important;
  font-size: 0.7rem !important;
}
.welcome-header-ar h5  {
  font-size: 0.9rem !important;
}
/* .nav-item-ar {
  font-family: Tajawal !important;
} */
.navbar {
  padding: 1rem 0 !important;
}

.step3-1400 {
  margin-top: 117px !important;
}

@media (max-width: 1201px) and (min-width: 991px) {
  .services-section .container {
    width: 880px !important;
  }
}

@media (min-width: 375px) and (max-width: 768px) {
  .rectangle2-mob2 {
    width: 90%;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
}

.slider-image {
  max-height: 309px !important;
}

@media (max-width: 400px) {
  .container-owl-carousel-just {
    width: 82% !important;
  }
}

@media (min-width: 1400px) {
  .carousel-inner {
    height: 700px !important;
  }
  .carousel-item {
    height: 700px !important;
  }
  .carousel-item img {
    object-fit: cover !important;
  }
}

@media (max-width: 991px) {
  .btn-out-menu {
    display: block !important;
  }
}

@media (max-width: 991px) {
  .btn-out-menu {
    display: block !important;
    width: auto;
  }
  .btn-in-menu {
    display: none !important;
  }
  .btn-out-menu a {
    padding: 0.4rem 0.7rem !important;
  }
}

.uni-numbers .row .col-md-5 img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.vision-mission-history .container h1 {
  background-color: #f9cb8f;
  color: #2d3339;
  text-align: center;
  border-radius: 30px;
  height: 50px;
  line-height: 50px;
}

.vision-mission-history .container h3 {
  text-align: center;
  margin: 2rem 0;
} 

.vision-mission-history .container div {
  display: flex;
  background-color: #f9cb8f;
  align-items: center;
  border-radius: 30px 30px;
}

.vision-mission-history .container div img {
  padding: 2rem;
  border-radius: 50px;
  width: 50%;
}







.vision-mission-history-AR .container h1 {
  background-color: #f9cb8f;
  color: #2d3339;
  text-align: center;
  border-radius: 30px;
  height: 50px;
  line-height: 50px;
}

.vision-mission-history-AR .container h3 {
  text-align: center;
  margin: 2rem 0;
} 

.vision-mission-history-AR .container div {
  display: flex;
  background-color: #f9cb8f;
  align-items: center;
  border-radius: 30px 30px;
}

.vision-mission-history-AR .container div img {
  padding: 2rem;
  border-radius: 50px;
  width: 50%;
}

@media (max-width: 768px) {
  .vision-mission-history .container div {
    flex-direction: column;
    padding: 0 2rem;
    text-align: center;
  }
  .vision-mission-history .container div img {
    width: 100%;
  }
  .vision-mission-history-AR .container div img {
    width: 100%;
  }
  .vision-mission-history-AR .container div {
    flex-direction: column-reverse;
    padding: 0 2rem;
  }
}

.university-container div div:last-of-type {
  margin-top: 1rem !important;
}

.loader {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

/* @media (max-width: 577px ) {
  .loader {
    
  right: 30%;
  }
} */

.loader span span {
  width: 20px;
  height: 20px;
  background-color: #eb8c1e;
}

.uni-row div div {
  height: 100%;
}

.uni-row div div img {
  max-height: 100px;
  max-width: 200px;
  min-width: 199px;
  min-height: 99px;
  object-fit: contain;
}

.application-container {
  border-radius: 30px;
  padding: 2rem;
}

.vision-mission-history .container div img {
  max-width: 50%;
  min-width: 49.5%;
  max-height: 400px;
  min-height: 399px;
  object-fit: cover;
}

.vision-mission-history-AR .container div img {
  max-width: 50%;
  min-width: 49.5%;
  max-height: 400px;
  min-height: 399px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .vision-mission-history .container div img {
    max-width: 100%;
  }
  .vision-mission-history .container div {
    padding: 0 0.5rem ;
  }
}

.filter-row select:focus {
  background-color: #f9cb8f;
  outline: none;
  /* border:  none; */
  transition: all 0.5s ease-in-out;
}


/* option:hover {
  background-color: red;
} */

/* select.decorated option:hover {
  box-shadow: 0 0 10px 100px #000000 inset;
} */

/* .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  left: 30px !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  left: 30px !important;

} */

/* .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  left: 85px !important;
} */

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  left: 76px;
  color: #eb8c1e !important;
  z-index: 100;
}

.css-88nv5c-MuiInputBase-root-MuiInput-root-MuiSelect-root:before {
  display: none;
}

.css-88nv5c-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
  display: none;
}

.css-88nv5c-MuiInputBase-root-MuiInput-root-MuiSelect-root {
  /* background-color: white; */
  z-index: 99;
}

.MuiMenuItem-root-ar {
  justify-content: flex-end !important;
}

.wrappp div {
  width: 15.666667%;
}

.wrappp-ar {
  flex-direction: row-reverse;
  width: 15.666667%;
}

.wrappp-ar div {
  width: 15.666667%;
}

.filter-Ar .MuiInput-root svg { 
  left: 0 !important;
}

.filter-Ar label {
  left: 36px;
}
.filter-Ar div div{
  text-align: right !important;
}

.MuiInput-root div {
  background: none !important;
}

.filter-Ar .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  left: 84px !important;
}

@media (max-width: 1400px) {
  /* .row {
    flex-direction: column !important;
  } */
  /* .wrappp-ar div {
    width: 14.666667%;
  } */
  .wrappp div {
    width: 13.666667%;
  }
  .wrappp-ar div {
    width: 13.666667%;
  }
  
}

@media (max-width: 1200px) {
  .wrappp-ar div {
    width: 12.666667%;
    text-align: center;
  }
  .wrappp div {
    width: 12.666667%;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .uni-row div div{
    height: 68%;
  }

  .wrappp-ar div {
    width: 24.666667%;
    text-align: center;
  }
  .wrappp div {
    width: 24.666667%;
    text-align: center;
  }

  .wrappp div:nth-child(4) {
    border-top: 1px solid #e68f24;
  }

  .wrappp div:nth-child(5) {
    border-top: 1px solid #e68f24;
  }
}


@media (max-width: 577px) {
  .row {
    flex-direction: column !important;
  }
  .wrappp div:nth-child(4) {
    border-top: none;
  }

  .wrappp div:nth-child(5) {
    border-top: none;
  }

  .wrappp-ar {
    align-items: center;
  }
  .wrappp {
    align-items: center;
  }

  .wrappp img {
    margin-bottom: 1rem;
    border-radius: 15px;
    max-height: 167px !important;
    min-height: 167px !important;
    height: 167 !important;
  }
  
  .wrappp-ar img {
    margin-bottom: 1rem;
    border-radius: 15px;
    max-height: 167px !important;
    min-height: 167px !important;
    height: 167 !important;
  }
  .wrappp-ar div:not(:nth-child(6)) {
    width: 100%;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) inset;
    margin: 1rem;
    background-color: transparent;
  }
  .wrappp div:not(:nth-child(6)) {
    width: 100%;
    text-align: center;
    background-color: transparent;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) inset;
    margin: 1rem;
  }
  .uni-container .filter .filter-row .filter_select {
    width: 83% !important;
  }
}

.uni-container .row .col-md-3 img {
  min-width: 100%;
}

/* @media (max-width: 992px) {
  .wrappp.row {
    flex-direction: column !important;
  }
} */

.footer-links a {
  min-width: 40px !important;
  min-height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.footer-links a:hover i {
  color: #e68f24;
  transition: all 0.5s ease-in-out;
}

input[type=search] {
  border-bottom: 1px solid #eb8c1e;
  outline: none;
  margin-bottom: 2rem;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.container.application-container.mt-5 {
  background-color: #7b7b7b;
  border-radius: 0px;
  border-top: 25px solid #f4911d;
}

.container.applicaiton2.application-container.mt-5.mb-5.py-1 {
  color: white;
}

.application-container .container input:hover {
  border: 1px solid #e68f24 !important;
}

.alert-danger {
  background-color: #f9cb8f !important;
  color: black !important;
  border: none;
}

.container.application-container.mt-5 p {
  color: white !important; 
}

.accordion-button.collapsed::after {
  background-image: url('./assets/images/plus-solid.svg') !important;
  color: rgb(187, 187, 187) !important;
}

.accordion-button.collapsed::after svg path {
  color: rebeccapurple;
}

.accordion-button::after {
  background-image: url('./assets/images/minus-solid-white-02.svg') !important;
  color: rgb(187, 187, 187) !important;
}

.accordion-button::after svg path {
  color: red;
}

.accordion-button {
  border-bottom: 5px solid #f4911d !important;
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.uni-container .filter {
  flex-direction: column !important;
  align-items: center;
}

.blog-btn {
  background-color: #f4911d !important;
}

.blog-btn:hover {
  background-color: #faae56 !important;
}

.Ar-modal-header .btn-close {
  margin-left: 0 !important;
}